import { createApp } from 'vue';
import { getSharedData } from './plugins/sharedData';
import { getGlobalData } from './plugins/globalData';
import App from './App.vue';
import router from './router';
import './registerServiceWorker'

const app = createApp(App);
app.use(router);

app.config.unwrapInjectedRef = true;
const sharedData = getSharedData();
const globalData = getGlobalData();

Promise.all([sharedData, globalData])
    .then(values => {
        app.config.globalProperties.$sharedData = values[0].data;
        app.config.globalProperties.$labels = values[1];
        app.mount('#app');
    })
    .catch(err => console.error(err));
